import Button from "@/components/shared/Button"
import { ROUTES } from "@/conf"
import Image from "next/image"
import Link from "next/link"
import { useTranslation } from "react-i18next"

interface LoginButtonProps {
  isAnonymousUser: boolean
  avatarUrl: string
  handleCloseMobileMenu?: () => void
}

const LoginButton = ({ isAnonymousUser, avatarUrl, handleCloseMobileMenu }: LoginButtonProps) => {
  const { t } = useTranslation("common")

  return isAnonymousUser ? (
    <Button color="primary">
      <Link href={ROUTES.login}>{t("login")}</Link>
    </Button>
  ) : (
    <div className="max-h-[64px] max-w-[64px] overflow-hidden lg:max-h-[48px] lg:max-w-[48px]">
      <Link href={ROUTES.profile} onClick={handleCloseMobileMenu}>
        <Image
          src={avatarUrl}
          alt="preview"
          className="h-[64px] w-[64px] rounded-full object-cover object-top lg:h-[48px] lg:w-[48px]"
          width={64}
          height={64}
        />
      </Link>
    </div>
  )
}

export default LoginButton
