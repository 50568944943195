import useMenuItem from "@/components/core/Menu/useMenuItem"
import Link from "next/link"
import { FC } from "react"
import { twMerge } from "tailwind-merge"

interface DesktopMenuItemProps {
  title: string
  route: string
  whiteVersion?: boolean
}
const DesktopMenuItem: FC<DesktopMenuItemProps> = ({ title, route, whiteVersion }) => {
  const isActive = useMenuItem(route)

  return (
    <Link
      id={route}
      href={route}
      className={twMerge(
        "flex w-fit items-center justify-center gap-1 p-3 leading-5 tracking-[-0.32px] text-primary-100 hover:text-primary-50",
        isActive && "rounded-lg bg-secondary-400 font-medium",
        whiteVersion && "text-primary-50",
      )}
    >
      {title}
    </Link>
  )
}

export default DesktopMenuItem
