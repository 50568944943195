export const EMAIL_REGEX =
  /^(?:(?:[a-zA-Z0-9_'^&/+-]|(?:\.(?!\.))){1,64}(?:\.(?:[a-zA-Z0-9_'^&/+-]|(?:\.(?!\.))){1,64})*|(?:\"(?:[\\x01-\\x09\\x0B\\x0C\\x0E-\\x1F\\x21\\x23-\\x5B\\x5D-\\x7F]|\\\\[\\x01-\\x09\\x0B\\x0C\\x0E-\\x7F])*\"))@(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?\.)+[a-zA-Z]{2,})$/i
export const PASSWORD_REGEX = /^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z0-9]{8,}$/i
export const ICO_REGEX = /^\d{8}$/i
export const STREET_REGEX = /^.+\s?\d+\/?\d*$/i
export const ZIP_CODE_REGEX = /^\d{3}\s?\d{2}$/i
export const PHONE_NUMBER_REGEX = /^\+\d{1,3}\s?\d{3}\s?\d{3}\s?\d{3}$/i
export const ALPHABET = "ABCDEFGHIJKLMNOPQRSTUVWXYZ"

export const ROUTES = {
  root: "/",
  register: "/register",
  login: "/login",
  reset: "/reset-password",
  verify: "/verify",
  map: "/map",
  helper: "/help",
  estate: "/estate",
  marketplace: "/marketplace",
  profile: "/profile",
  contacts: "/contacts",
  partners: "/partners",
  about: "/about",
  terms: "/terms",
  cartoonDetail: "/cartoon",
  education: "/education",
  educationDetail: "/education",
  tournament: "/tournament",
  gdpr: "/assets/zasady_zpracovani_osobnich_udaju.pdf",
}

export const API_ROUTES = {
  register: `${process.env.NEXT_PUBLIC_API_URL}/users/sign-up`,
}
