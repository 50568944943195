import LoginButton from "@/components/core/LoginButton/LoginButton"
import MobileMenuItem from "@/components/core/Menu/MobileMenu/MobileMenuItem"
import { Menu } from "@/components/core/Menu/menuTypes"
import useMenu from "@/components/core/Menu/useMenu"
import Logo from "@/components/shared/Logo"
import MoneyBalance from "@/components/shared/MoneyBalance"
import Typography from "@/components/shared/Typography"
import Image from "next/image"
import Link from "next/link"

interface MobileMenuProps {
  menuItems: Menu
}
const MobileMenu = ({ menuItems }: MobileMenuProps) => {
  const { orangeVersion, playerData, user, handleOpenMobileMenu, isMobileMenuOpen, handleCloseMobileMenu } = useMenu()

  return (
    <div className="z-40 mx-auto w-full max-w-screen-xl lg:hidden">
      <div className="flex items-center justify-between px-6">
        <Link href="/" className="flex items-center">
          <Logo whiteVersion={orangeVersion} width={128} />
        </Link>
        <Image
          alt="Menu"
          src="/assets/images/menu.svg"
          onClick={handleOpenMobileMenu}
          width={32}
          height={32}
          className="cursor-pointer"
        />
      </div>
      {isMobileMenuOpen && (
        <div className="fixed left-0 top-0 z-30 h-full w-full bg-primary-700">
          <div className="border-b border-white/10 bg-primary-500">
            <div className="flex flex-col gap-y-8 px-6 pb-6 pt-5">
              <div className="flex items-center justify-between ">
                <Link href="/" className="flex items-center" onClick={handleCloseMobileMenu}>
                  <Logo whiteVersion={orangeVersion} />
                </Link>
                <Image
                  alt="Menu"
                  src="/assets/images/menuClose.svg"
                  onClick={handleCloseMobileMenu}
                  width={32}
                  height={32}
                  className="cursor-pointer"
                />
              </div>
              <div className="flex items-center gap-x-4">
                <LoginButton
                  avatarUrl={playerData?.roundedAvatarUrl || "/assets/images/user_placeholder.svg"}
                  isAnonymousUser={user?.isAnonymous || false}
                  handleCloseMobileMenu={handleCloseMobileMenu}
                />
                <div className="flex flex-col justify-center gap-y-2">
                  {!user?.isAnonymous && user?.displayName && <Typography type="h4">{user?.displayName}</Typography>}
                  <MoneyBalance count={playerData?.groshakBalance || 0} />
                </div>
              </div>
            </div>
          </div>
          <div className="mt-6 px-6">
            <nav className="flex flex-col gap-y-8">
              {menuItems.map((i, index) => {
                const { route, title } = i
                return (
                  <MobileMenuItem
                    whiteVersion={orangeVersion}
                    title={title}
                    route={route}
                    key={index}
                    onClickHandler={handleCloseMobileMenu}
                  />
                )
              })}
            </nav>
          </div>
        </div>
      )}
    </div>
  )
}

export default MobileMenu
