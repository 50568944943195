import Typography from "@/components/shared/Typography"
import { ROUTES } from "@/conf"
import Link from "next/link"
import React, { PropsWithChildren } from "react"

const FooterNav: React.FC<PropsWithChildren> = ({ children }) => {
  const menuItemsLeft = [
    {
      title: "Komiksy",
      route: ROUTES.root,
    },
    {
      title: "Něco více",
      route: ROUTES.education,
    },
    {
      title: "Mapa",
      route: ROUTES.map,
    },
    {
      title: "Tržiště",
      route: ROUTES.marketplace,
    },
    {
      title: "Panství",
      route: ROUTES.estate,
    },
  ]

  const menuItemsRight = [
    {
      title: "Profil",
      route: ROUTES.profile,
    },
    {
      title: "Kontakty",
      route: ROUTES.contacts,
    },
    {
      title: "Partneři",
      route: ROUTES.partners,
    },
    {
      title: "O projektu",
      route: ROUTES.about,
    },
    {
      title: "Ochrana osobních údajů",
      route: ROUTES.terms,
    },
  ]
  return (
    <nav className="flex">
      <ul className="mr-20 flex flex-col">
        {menuItemsLeft.map((link, index) => (
          <Link href={link.route} key={index} className="mb-3 block text-primary-200 last:mb-0 hover:text-primary-50">
            <Typography type="l">{link.title}</Typography>
          </Link>
        ))}
      </ul>

      <ul className="flex flex-col">
        {menuItemsRight.map((link, index) => (
          <Link href={link.route} key={index} className="mb-3 block text-primary-200 last:mb-0 hover:text-primary-50">
            <Typography type="l">{link.title}</Typography>
          </Link>
        ))}
      </ul>
    </nav>
  )
}

export default FooterNav
