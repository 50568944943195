import Typography from "@/components/shared/Typography"
import Image from "next/image"

interface MoneyBalanceProps {
  count: number
}

const MoneyBalance = ({ count }: MoneyBalanceProps) => {
  return (
    <div className="flex w-fit items-center justify-center gap-x-2 rounded-full bg-primary-600 py-1 pl-2 pr-3">
      <Image src="/assets/images/grosak.svg" alt="logo" width={20} height={20} />
      <Typography className="text-gold" type="btn">
        {count}
      </Typography>
    </div>
  )
}

export default MoneyBalance
