import LoginButton from "@/components/core/LoginButton/LoginButton"
import DesktopMenuItem from "@/components/core/Menu/DesktopMenu/DesktopMenuItem"
import { Menu } from "@/components/core/Menu/menuTypes"
import useMenu from "@/components/core/Menu/useMenu"
import Logo from "@/components/shared/Logo"
import MoneyBalance from "@/components/shared/MoneyBalance"
import Link from "next/link"

interface DesktopMenuProps {
  menuItems: Menu
}

const DesktopMenu = ({ menuItems }: DesktopMenuProps) => {
  const { orangeVersion, playerData, user } = useMenu()

  return (
    <div className="mx-auto hidden w-full max-w-screen-xl lg:block">
      <div className="flex items-center justify-between px-6">
        <Link href="/" className="flex items-center">
          <Logo whiteVersion={orangeVersion} width={128} />
        </Link>
        <nav className="flex gap-x-4">
          {menuItems.map((i, index) => {
            const { route, title } = i
            return <DesktopMenuItem whiteVersion={orangeVersion} title={title} route={route} key={index} />
          })}
        </nav>
        <div className="flex items-center gap-x-4">
          <MoneyBalance count={playerData?.groshakBalance || 0} />
          <LoginButton
            avatarUrl={playerData?.roundedAvatarUrl || "/assets/images/user_placeholder.svg"}
            isAnonymousUser={user?.isAnonymous || false}
          />
        </div>
      </div>
    </div>
  )
}

export default DesktopMenu
