import Image from "next/image"
import { FC } from "react"

interface LogoProps {
  width?: number
  height?: number
  className?: string
  whiteVersion?: boolean
}

const Logo: FC<LogoProps> = ({ width = 96, height = 40, className = "", whiteVersion }) => {
  const imageUrl = whiteVersion ? "/assets/images/logo_white.svg" : "/assets/images/logo.svg"

  return <Image src={imageUrl} alt="logo" width={width} height={height} priority={true} className={className} />
}

export default Logo
