import useMediaQueryBase from "./useMediaQueryBase"

interface useMediaQuery {
  breakpoint?: 640 | 768 | 1024 | 1280
}

const useMediaQuery = ({ breakpoint = 1024 }: useMediaQuery) => {
  const isMediaQuery = useMediaQueryBase(`(min-width:${breakpoint}px)`)
  return { isMediaQuery }
}

export default useMediaQuery
