import { useGetAvatars, useUpdatePlayerAvatar } from "@/api/player/hooks"
import Button from "@/components/shared/Button"
import CharacterItem from "@/components/shared/CharacterItem"
import Typography from "@/components/shared/Typography"
import { useCallback, useState } from "react"
import { useTranslation } from "react-i18next"

interface AvatarModalAfterRegistration {
  onCloseModal: () => void
}

const AvatarModalAfterRegistration = ({ onCloseModal }: AvatarModalAfterRegistration) => {
  const { avatars } = useGetAvatars()
  const { mutateAsync: updatePlayerAvatar } = useUpdatePlayerAvatar()

  const [selectedAvatarId, setSelectedAvatarId] = useState("")

  const handleSetAvatarId = useCallback((avatarId: string) => {
    setSelectedAvatarId(avatarId)
  }, [])

  const handleConfirmAvatarId = useCallback(() => {
    updatePlayerAvatar(selectedAvatarId)
    onCloseModal()
  }, [onCloseModal, selectedAvatarId, updatePlayerAvatar])

  const { t } = useTranslation("common")
  return (
    <div className="flex flex-col gap-y-3">
      <div className="flex flex-col gap-y-2">
        <Typography className="text-primary-700" type="h2">
          {t("rewardForRegistration")}
        </Typography>
        <Typography className="text-primary-500" type="l">
          {t("chooseAvatar")}
        </Typography>
      </div>
      <div className="mt-6 grid grid-cols-3 gap-2">
        {(avatars || []).map((i, index) => {
          const { imageUrl, avatarId, isSelected } = i
          return (
            <CharacterItem
              imageUrl={imageUrl}
              key={index}
              avatarId={avatarId}
              selected={isSelected}
              onChangeAvatarId={handleSetAvatarId}
              selectedAvatarId={selectedAvatarId}
            />
          )
        })}
      </div>
      <Button onClick={handleConfirmAvatarId} fullWidth>
        {t("confirm")}
      </Button>
    </div>
  )
}

export default AvatarModalAfterRegistration
