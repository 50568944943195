import {
  buyCoupon,
  buyMarketplaceItem,
  deletePlayer,
  getAvatars,
  getMyCoupons,
  getPersonalData,
  playerSearchMarketplaceItems,
  playerUseCoupon,
  selectPlayerAccompaniment,
  updatePlayerAvatar,
  updatePlayerData,
} from "@/api/player/api"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { SearchPlayerItemRequest } from "swagger/models"
import { joinTournament } from "../tournament/api"

export const useUpdatePlayer = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: ["updatePlayer"],
    mutationFn: updatePlayerData,
    onSuccess: () => {
      queryClient.invalidateQueries(["playerData"])
    },
  })
}

export const useDeletePlayer = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: ["deletePlayer"],
    mutationFn: deletePlayer,
    onSuccess: () => {
      queryClient.invalidateQueries(["playerData"])
    },
  })
}

export const useUpdatePlayerAvatar = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: ["updatePlayerAvatar"],
    mutationFn: updatePlayerAvatar,
    onSuccess: () => {
      queryClient.invalidateQueries(["playerData"])
      queryClient.invalidateQueries(["avatars"])
      queryClient.invalidateQueries(["estateRoomDetail"])
    },
  })
}

export const useGetPlayerData = () => {
  const { data: { data: playerData } = {}, ...rest } = useQuery(["playerData"], () => getPersonalData(), {
    retry: 1,
    enabled: true,
    cacheTime: 1,
  })

  return {
    playerData,
    ...rest,
  }
}

export const useGetAvatars = () => {
  const { data: { data: avatars } = {}, ...rest } = useQuery(["avatars"], () => getAvatars(), {
    retry: 1,
    enabled: true,
  })

  return {
    avatars,
    ...rest,
  }
}
export const usePlayerSearchMarketplaceItems = (
  body: SearchPlayerItemRequest,
  page?: number,
  size?: number,
  sort?: string[],
) => {
  const { data: { data: playerItems } = {}, ...rest } = useQuery(
    ["playerItems", body.itemType],
    () => playerSearchMarketplaceItems(body, page, size, sort),
    {
      retry: 1,
      enabled: true,
    },
  )

  return {
    playerItems,
    ...rest,
  }
}

export const useBuyMarketplaceItem = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: ["buyMarketplaceItem"],
    mutationFn: buyMarketplaceItem,
    onSuccess: () => {
      queryClient.invalidateQueries(["playerData"])
      queryClient.invalidateQueries(["playerItems"])
    },
  })
}

export const useSelectPlayerAccompaniment = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: ["selectPlayerAccompaniment"],
    mutationFn: selectPlayerAccompaniment,
    onSuccess: () => {
      queryClient.invalidateQueries(["playerData"])
      queryClient.invalidateQueries(["playerItems"])
      queryClient.invalidateQueries(["estateRoomDetail"])
    },
  })
}

export const useBuyCoupon = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: ["buyCoupon"],
    mutationFn: buyCoupon,
    onSuccess: () => {
      queryClient.invalidateQueries(["playerData"])
    },
  })
}

export const useGetMyCoupons = () => {
  const { data: { data: myCoupons } = {}, ...rest } = useQuery(["myCoupons"], () => getMyCoupons(), {
    retry: 1,
    enabled: true,
  })

  return {
    myCoupons,
    ...rest,
  }
}

export const useClaimCoupon = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: ["playerUseCoupon"],
    mutationFn: ({ used, campaignId }: { used: boolean; campaignId: string }) => playerUseCoupon(used, campaignId),
    onSuccess: () => {
      queryClient.invalidateQueries(["myCoupons"])
    },
  })
}
