import { useGetPlayerData } from "@/api/player/hooks"
import AvatarModalAfterRegistration from "@/components/core/AvatarModalAfterRegistration"
import Footer from "@/components/core/Footer"
import Menu from "@/components/core/Menu"
import Modal from "@/components/shared/Modal"
import { ROUTES } from "@/conf"
import { getAuth } from "firebase/auth"
import { useRouter } from "next/router"
import { FC, PropsWithChildren, useCallback, useEffect, useState } from "react"
import { useCookies } from "react-cookie"

const MainLayout: FC<PropsWithChildren> = ({ children }) => {
  const [footerVisible, setFooterVisible] = useState<boolean>(true)
  const router = useRouter()

  const { playerData, isLoading: isLoadingPlayerData } = useGetPlayerData()

  const [isOpenAvatarModal, setIsOpenAvatarModal] = useState(false)
  const [cookies] = useCookies(["marketplace"])

  useEffect(() => {
    getAuth().onAuthStateChanged((user) => {
      if (user) {
        if (!user?.isAnonymous && !user?.emailVerified) {
          router.push(ROUTES.verify)
        }

        if (!user?.isAnonymous && user?.emailVerified && !isLoadingPlayerData && !playerData?.avatarUrl) {
          setIsOpenAvatarModal(true)
        }
        if (
          !user?.isAnonymous &&
          user?.emailVerified &&
          !isLoadingPlayerData &&
          playerData?.avatarUrl &&
          !isOpenAvatarModal &&
          cookies.marketplace
        ) {
          router.replace(ROUTES.marketplace)
        }
      }
    })
  }, [cookies.marketplace, isLoadingPlayerData, isOpenAvatarModal, playerData, router])

  const handleCloseAvatarPopup = useCallback(() => {
    setIsOpenAvatarModal(false)
  }, [])

  useEffect(() => {
    if (router.asPath.includes("?section=game") || router.asPath.includes("?section=bonus")) {
      setFooterVisible(false)
    } else {
      setFooterVisible(true)
    }
  }, [router, router.asPath])

  return (
    <div className="flex min-h-screen flex-col justify-between bg-primary-700">
      <Menu />
      <div className="relative min-h-[calc(100dvh-72px)] overflow-hidden lg:min-h-[calc(100dvh-80px)]">
        <>
          {children}
          <Modal isVisible={isOpenAvatarModal} setIsVisible={setIsOpenAvatarModal} showCloseIcon={false}>
            <AvatarModalAfterRegistration onCloseModal={handleCloseAvatarPopup} />
          </Modal>
        </>
      </div>
      {footerVisible && <Footer />}
    </div>
  )
}

export default MainLayout
