import useMenuItem from "@/components/core/Menu/useMenuItem"
import Typography from "@/components/shared/Typography"
import Link from "next/link"
import { FC } from "react"
import { twMerge } from "tailwind-merge"

interface MobileMenuItemProps {
  title: string
  route: string
  onClickHandler: () => void
  whiteVersion?: boolean
}
const MobileMenuItem: FC<MobileMenuItemProps> = ({ title, route, onClickHandler, whiteVersion }) => {
  const isActive = useMenuItem(route)

  return (
    <Link
      href={route}
      onClick={onClickHandler}
      className={twMerge("hover:text-secondary-400", isActive && "text-secondary-400")}
    >
      <Typography type="h2">{title}</Typography>
    </Link>
  )
}

export default MobileMenuItem
