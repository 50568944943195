import { useGetPlayerData } from "@/api/player/hooks"
import { useAuthContext } from "@/components/core/Auth/AuthProvider"
import { usePathname } from "next/navigation"
import { useRouter } from "next/router"
import { useCallback, useEffect, useState } from "react"

const useMenu = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false)

  const { user } = useAuthContext()

  useEffect(() => {
    if (isMobileMenuOpen) {
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.overflow = ""
    }
  }, [isMobileMenuOpen])

  const handleOpenMobileMenu = useCallback(() => {
    setIsMobileMenuOpen(true)
  }, [])
  const handleCloseMobileMenu = useCallback(() => {
    setIsMobileMenuOpen(false)
  }, [])

  const pathname = usePathname()
  const router = useRouter()

  const orangeVersion =
    pathname.startsWith("/cartoon") && router.query?.section === "bonus" && router.query?.bonusConfirmation !== "true"

  const { playerData } = useGetPlayerData()

  return { isMobileMenuOpen, handleOpenMobileMenu, handleCloseMobileMenu, playerData, orangeVersion, user }
}

export default useMenu
