import Container from "@/components/shared/Container"
import Logo from "@/components/shared/Logo"
import Typography from "@/components/shared/Typography"
import { useTranslation } from "next-i18next"
import Image from "next/image"
import Link from "next/link"
import React from "react"
import FooterNav from "./FooterNav"

const Footer: React.FC = () => {
  const { t } = useTranslation(["common"])
  return (
    <footer className="bg-primary-500">
      <div className="py-10">
        <Container className="md:flex">
          <div className="mb-5 max-w-xs md:mb-0 md:mr-[137px] lg:mr-[137px]">
            <Link href="/" className="mb-8 flex items-center">
              <Logo width={160} />
            </Link>

            <Typography type="m" className="hidden text-primary-200 lg:block">
              {t("footer.description")}
            </Typography>
          </div>
          <div>
            <FooterNav />
          </div>
        </Container>
      </div>
      <div className="bg-primary-600">
        <Container className="flex items-center justify-between ">
          <Typography className="text-primary-300">{t("copyright", { year: new Date().getFullYear() })}</Typography>
          <Image src="/assets/images/logo_scholarum.svg" width={160} height={160} alt="Scholarum" />
        </Container>
      </div>
    </footer>
  )
}

export default Footer
