import { axiosInstance } from "@/api/axiosInstance"
import { AxiosRequestConfig } from "axios"
import { SearchPlayerItemRequest } from "swagger/models"
import { UpdatePlayerPersonalDataRequest } from "swagger/models/update-player-personal-data-request"
import { PlayerApi, PlayerPartnerProductApi } from "../../../swagger/api"
import { Configuration } from "../../../swagger/configuration"
import { getFirebaseIDToken } from "../auth/firebase"

const getAccessToken = async () => {
  const accessToken = await getFirebaseIDToken()
  return accessToken
}

const getApiConfig = () =>
  new Configuration({
    basePath: process.env.NEXT_PUBLIC_API_URL,
    //@ts-ignore
    accessToken: getAccessToken,
  })

const apiConfiguration = getApiConfig()

const PLAYER_API = new PlayerApi(apiConfiguration, apiConfiguration.basePath, axiosInstance)
const PARTENER_PRODUCTS_API = new PlayerPartnerProductApi(apiConfiguration, apiConfiguration.basePath, axiosInstance)

export const getPersonalData = async (options?: AxiosRequestConfig) => PLAYER_API.getPersonalData(options)

export const deletePlayer = async (options?: AxiosRequestConfig) => PLAYER_API.deletePlayer(options)

export const updatePlayerData = async (body: UpdatePlayerPersonalDataRequest, options?: AxiosRequestConfig) =>
  PLAYER_API.updatePersonalData(body, options)

export const getAvatars = async (options?: AxiosRequestConfig) => PLAYER_API.findAllAvatars(options)

export const updatePlayerAvatar = async (body: string, options?: AxiosRequestConfig) =>
  PLAYER_API.selectAvatar(body, options)

export const buyMarketplaceItem = async (itemId: string, options?: AxiosRequestConfig) =>
  PLAYER_API.buyItem(itemId, options)

export const playerSearchMarketplaceItems = async (
  body: SearchPlayerItemRequest,
  page?: number,
  size?: number,
  sort?: string[],
  options?: AxiosRequestConfig,
) => PLAYER_API.searchPlayerItems(body, page, size, sort, options)

export const selectPlayerAccompaniment = async (itemId: string, options?: AxiosRequestConfig) =>
  PLAYER_API.selectAccompaniment(itemId, options)

export const buyCoupon = async (campaignId: string, options?: AxiosRequestConfig) =>
  PARTENER_PRODUCTS_API.buyPartnerProduct({ campaignId }, options)

export const getMyCoupons = async (options?: AxiosRequestConfig) =>
  PARTENER_PRODUCTS_API.getPlayerPartnerProducts(options)

export const playerUseCoupon = async (used: boolean, campaignId: string, options?: AxiosRequestConfig) =>
  // eslint-disable-next-line react-hooks/rules-of-hooks
  PARTENER_PRODUCTS_API.usePartnerProduct({ used }, campaignId, options)
