import DesktopMenu from "@/components/core/Menu/DesktopMenu"
import MobileMenu from "@/components/core/Menu/MobileMenu"
import { Menu as MenuType } from "@/components/core/Menu/menuTypes"
import useMenu from "@/components/core/Menu/useMenu"
import { ROUTES } from "@/conf"
import useMediaQuery from "@/hooks/useMediaQuery"
import { twMerge } from "tailwind-merge"
import { useEffect, useState } from "react"

const menuItems: MenuType = [
  {
    title: "Komiksy",
    route: ROUTES.root,
  },
  {
    title: "Něco více",
    route: ROUTES.education,
  },
  {
    title: "Mapa",
    route: ROUTES.map,
  },
  {
    title: "Tržiště",
    route: ROUTES.marketplace,
  },
  {
    title: "Panství",
    route: ROUTES.estate,
  },
  {
    title: "Nápověda",
    route: ROUTES.helper,
  },
  {
    title: "Turnaj",
    route: ROUTES.tournament,
  },
]

const Menu = () => {
  const { orangeVersion } = useMenu()
  const [boom, setBoom] = useState(false)

  const { isMediaQuery: isDesktop } = useMediaQuery({ breakpoint: 1024 })

  useEffect(() => {
    setBoom(true)
  }, [])

  return (
    <header
      className={twMerge(
        "flex h-20 items-center border-b border-white/10 bg-primary-700/90",
        orangeVersion && "bg-stateBonus-100",
      )}
    >
      {isDesktop ? <DesktopMenu menuItems={menuItems} /> : <MobileMenu menuItems={menuItems} />}
    </header>
  )
}

export default Menu
