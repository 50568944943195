import { ROUTES } from "@/conf"
import { usePathname } from "next/navigation"

const useMenuItem = (route: string) => {
  const pathname = usePathname()

  const isActive = route === ROUTES.root ? pathname === route : pathname.includes(route)

  return isActive
}

export default useMenuItem
