import { Modal as ImportedModal } from "react-responsive-modal"
import "react-responsive-modal/styles.css"
import { twMerge } from "tailwind-merge"

interface ModalProps {
  isVisible: boolean
  setIsVisible: (v: boolean) => void
  children: React.ReactNode
  showCloseIcon?: boolean
  closeOnOverlayClick?: boolean
  fullWidth?: boolean
  withoutSpacing?: boolean
}
const Modal = ({
  children,
  isVisible,
  setIsVisible,
  showCloseIcon,
  closeOnOverlayClick,
  fullWidth,
  withoutSpacing,
}: ModalProps) => {
  const onCloseModal = () => setIsVisible(false)

  const modalStyles = twMerge(
    "rounded-2xl !p-6 sm:w-[400px] !m-4 w-[calc(100%-32px)]",
    fullWidth && "!w-[calc(100%-32px)] sm:!max-w-[calc(100%-120px)]",
    withoutSpacing && "!p-2",
  )

  return (
    <ImportedModal
      showCloseIcon={showCloseIcon}
      open={isVisible}
      onClose={onCloseModal}
      center
      blockScroll
      closeOnOverlayClick={closeOnOverlayClick}
      classNames={{ modal: modalStyles }}
      styles={{
        closeButton: { top: "24px", right: "24px" },
        modalContainer: { background: "rgba(21, 24, 41, 0.80)" },
      }}
    >
      <div className="p-2">{children}</div>
    </ImportedModal>
  )
}

export default Modal
