import Image from "next/image"
import { useCallback, useEffect } from "react"
import { twMerge } from "tailwind-merge"

interface CharacterItemProps {
  imageUrl: string
  selected: boolean
  avatarId: string
  onChangeAvatarId: (avatarId: string) => void
  selectedAvatarId: string
}

const CharacterItem = ({ imageUrl, selected, avatarId, onChangeAvatarId, selectedAvatarId }: CharacterItemProps) => {
  useEffect(() => {
    if (selected) {
      onChangeAvatarId(avatarId)
    }
  }, [avatarId, onChangeAvatarId, selected])

  const handleSelectAvatar = useCallback(
    (avatarId: string) => {
      onChangeAvatarId(avatarId)
    },
    [onChangeAvatarId],
  )

  return (
    <article
      onClick={() => handleSelectAvatar(avatarId)}
      className={twMerge(
        "relative cursor-pointer rounded-2xl border-2 border-white-50 bg-white-50 p-4",
        selectedAvatarId === avatarId && "border-success-500",
      )}
    >
      {selectedAvatarId === avatarId && (
        <div className="absolute right-4 top-4">
          <Image alt="" src="/assets/images/checkbox.svg" width={24} height={24} />
        </div>
      )}
      <div className="relative flex h-[115px] w-full items-center justify-center">
        <Image alt={imageUrl} src={imageUrl} fill className="max-h-[115px] w-full object-contain" />
      </div>
    </article>
  )
}

export default CharacterItem
