import { useEffect, useState } from "react"

const useMediaQueryBase = (query: string): boolean => {
  const [matches, setMatches] = useState(() => {
    if (typeof window !== "undefined") {
      return window.matchMedia(query).matches
    }
    return false
  })

  useEffect(() => {
    const matchMedia = window.matchMedia(query)

    function handleChange(e: MediaQueryListEvent | MediaQueryListEventInit) {
      if (typeof e.matches === "boolean") {
        setMatches(e.matches)
      }
    }

    handleChange(matchMedia)

    matchMedia.addEventListener("change", handleChange)

    return () => {
      matchMedia.removeEventListener("change", handleChange)
    }
  }, [query])

  return matches
}

export default useMediaQueryBase
